@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,600|Raleway:200,400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Font import */

/* Reset */
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
td,
tr,
hr,
blockquote {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

button,
a {
  outline: none;
}

/* Main */

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
}

.background-container {
  background-color: #000000;
  height: 100vh;
}

header {
  padding-top: 10px;
}

.row-container {
  max-width: 769px;
  display: flex;
  margin: 0px auto;
  padding: 0 3%;
}

.logo-container {
  display: flex;
}

.header-buttons-container {
  margin-left: auto;
}

ul.header-buttons-list {
  margin-top: 5px;
  list-style: none;
  display: flex;
}

ul.header-buttons-list li {
  margin-left: 15px;
}

h1.logo-image {
  position: relative;
}

h1.logo-text {
  margin-left: 10px;
  margin-top: 3px;
  font-size: 2em;
  font-weight: 200;
  font-family: "Raleway", sans-serif;
}

h1.logo-text .big-logo-letter {
  font-weight: 600;
}

.main-content {
  margin: 10px 0px;
}

.photo-carousel-container {
  margin: 0px auto;
  padding: 10px 10px;
}

.photo-carousel {
  position: relative;
}

.photo-carousel .scene {
  overflow: hidden;
}

.photo-carousel .slide {
  margin: 0px auto;
  height: 498px;
}

.slide-image-container {
  max-width: 400px;
  margin: 10px auto;
}

.slide-image-container img {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 2px;
  box-sizing: border-box;
}

.photo-carousel .slide.current-slide.above {
  -webkit-animation-name: slideinDown;
          animation-name: slideinDown;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  position: relative;
}

.photo-carousel .slide.current-slide.below {
  -webkit-animation-name: slideinUp;
          animation-name: slideinUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  position: relative;
}

.slide-text-content {
  height: 80px;
  max-width: 25em;
}

.slide-description {
  text-align: left;
  font-size: 0.85em;
  font-weight: 300;
  height: 50px;
}

.navigation-arrow {
  display: inline-block;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: transparent;
  border: 0px none;
  cursor: pointer;
}

.navigation-arrow.up {
  top: -30px;
}

.navigation-arrow.down {
  bottom: -50px;
}

.example-enter {
  opacity: 0;
}

.example-enter.example-enter-active {
  opacity: 1;
  -webkit-transition: opacity 1500ms;
  transition: opacity 1500ms;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0;
  -webkit-transition: opacity 1000ms;
  transition: opacity 1000ms;
}

footer {
  margin-top: 15px;
}

.footer-text {
  text-align: right;
  width: 100%;
  font-size: 0.5em;
}

.modal-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(27, 25, 25, 0.86);
}

.final-image {
  width: 100%;
  height: 100vh;
  background-image: url("/img/photos/slide_final.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* Responsive styles */

@media screen and (max-width: 420px) {
  .slide-image-container {
    max-width: 300px;
    max-height: 300px;
  }

  .photo-carousel .scene {
    height: 452px;
  }

  .slide-text-content {
    height: 80px;
    max-width: 18.75em;
  }

  .slide-description {
    height: 90px;
  }

  .carousel-nav {
    right: -10px;
  }

  .navigation-arrow.up {
    top: -15px;
  }

  .navigation-arrow.down {
    bottom: -34px;
  }
}

@media screen and (min-width: 420px) {
  .photo-carousel .scene {
    overflow: hidden;
    height: 500px;
  }
}

@media screen and (min-width: 769px) {
  body {
    font-size: 1em;
  }

  h1.logo-text {
    font-size: 2.2em;
    margin-top: 0px;
  }

  .photo-carousel .scene {
    height: 500px;
  }

  .slide-image-container {
    margin: 10px auto;
  }

  .photo-carousel .slide {
    height: 500px;
  }

  .main-content {
    margin: 20px 0px;
  }
}

@media screen and (min-height: 950px) {
  body {
    font-size: 1.2em;
  }
  .photo-carousel .scene {
    height: 780px;
  }

  .photo-carousel .slide {
    height: 780px;
  }

  .slide-text-content {
    max-width: 100%;
  }

  .slide-image-container {
    max-width: 800px;
    max-height: 800px;
  }
}

