/* Font import */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,600|Raleway:200,400,600&display=swap");

/* Reset */
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
td,
tr,
hr,
blockquote {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

button,
a {
  outline: none;
}

/* Main */

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
}
